<script>
  export let name;
  export let required = false;
  export let placeholder = "";
  export let disabled = false;
  export let classList = "";
  export let error = null;
  export let value = "";
</script>

<label
  class={`block border border-gray-200 p-2 ${disabled ? 'bg-gray-200' : 'bg-white'} ${classList}`}>
  <span class="text-sm text-gray-600">
    <slot />
  </span>
  <input
    class={`block w-full bg-transparent text-lg ${disabled ? 'text-gray-600' : 'text-black'}`}
    type="text"
    {name}
    {placeholder}
    {required}
    {disabled}
    bind:value />
</label>

{#if error}
<p class="error error--static">{error}</p>
{/if}
