<script>
  import { step } from "../stores.js";
  import Arrow from "../icons/Arrow.svelte";

  export let disabled = false;
  export let text = "Next Step";
</script>

<button
  {disabled}
  class={`${disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-brand-orange'} text-white text-sm rounded-lg p-4 inline-flex items-center leading-none`}
  on:click
>
  <span class="pr-2">{text}</span>
  <Arrow />
</button>
