<script>
  import BackButton from "../core/BackButton.svelte";
  import NextButton from "../core/NextButton.svelte";
  import Step from "../core/Step.svelte";
  import {enquiry, step} from "../stores";
  import axios from 'axios';
  import { onMount } from 'svelte';

  let volumes = [];
  let option = null;

  onMount(() => {
    axios.get('/api/volumes').then(res => {
      volumes = res.data.data
    });

    if ($enquiry.volume && $enquiry.volume.id) {
      option = $enquiry.volume.id;
    }
  });

  function selectVolume() {
    axios.post('/api/enquires/select/volume', {
      enquiry_id: $enquiry.enquiry_id,
      volume_id: option
    }).then(res => {
      enquiry.set(res.data.data);
      step.update(n => n + 1)
    }).catch(err => {
      console.log(err);
    })
  }
</script>

<Step>
  <div
    class="flex flex-col-reverse md:flex-row md:justify-between items-start
    md:items-center"
    slot="header">
    <h1 class="text-5xl font-bold leading-none mt-4 md:mt-0">
      Select a volume
    </h1>

    <NextButton on:click={selectVolume} disabled={!option} />
  </div>
  <div class="grid p-4 grid-gap-4 grid-columns-12">
    {#each volumes as volume, i}
      <label
        class={`block border col-span-12 sm:col-span-6 ${volume.featured ? 'md:col-span-4' : 'md:col-span-3'} ${option === volume.id ? 'bg-gray-100' : 'bg-white'}`}>
        <div
          class={`p-8 text-5xl font-bold text-center leading-none border-t-4 ${option === volume.id ? 'border-brand-orange' : 'border-white'}`}>
          {volume.vol_1}L
          <span class="block text-xs uppercase tracking-widest my-2">Versus</span>
          <span class="text-brand-orange">{volume.vol_2}L</span>
        </div>
        <div class="px-4">
          <div
            class="border-t py-4 flex justify-between items-center leading-none">
            <span class="text-sm font-bold">Select Volume</span>
            <input
              type="radio"
              name="volume"
              value={volume.id}
              bind:group={option}
              class={`form-radio text-brand-orange border-brand-orange block ml-auto w-5 h-5 ${option === volume.id ? 'focus:border-transparent focus:shadow-none' : ''}`} />
          </div>
        </div>
      </label>
    {/each}
  </div>
</Step>
