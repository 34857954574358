<script>
  export let hidePercentage = false;
  export let data;
  export let selectedCurrency;
</script>

{#if data && data.tooling}
  <div>
    {#if hidePercentage}
    <div
      class="border border-gray-200 flex justify-between items-center p-4 mb-3
      text-sm"
      >
      <span class="font-bold">Reichert Wear Test Result</span>
      <span>{data.tooling.reichertWearTestResult}%</span>
    </div>
    {/if}
    <div
      class="border border-gray-200 flex justify-between items-center p-4 mb-3
      text-sm">
      <span class="font-bold">Potential Tool Spend Reduction</span>
      <span>{data.tooling.potentialToolingSpendReduction}%</span>
    </div>
    <div class="border-t-4 border-brand-orange">
      <div
        class="border border-gray-200 flex justify-between items-center px-4
        py-8">
        <div class="flex flex-col leading-tight">
          <span class="font-bold">You could save</span>
          <span class="text-gray-600 text-xs">
            <span class="text-brand-orange">*</span>
            Per year with ROCOL
          </span>
        </div>
        <span class="text-5xl font-bold leading-none">
          {selectedCurrency.symbol}{data.tooling.savings.toLocaleString(undefined, {maximumFractionDigits:2})}
          <sup class="text-brand-orange">*</sup>
        </span>
      </div>
    </div>
  </div>
{:else}
  <div class="border-l-4 border-brand-orange pl-4">
    <h4 class="text-lg font-bold mb-1">No data to show</h4>
    <p class="leading-tight">
      Please provide details to the left to be able to compare your product
      against a Rocol product.
    </p>
  </div>
{/if}
