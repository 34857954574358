<script>
  import formatNumber from "../helpers/format-number";
  export let data;
  export let selectedCurrency;
</script>

{#if data && data.total}
  <div
    class="border-t-4 border-brand-orange px-8 py-16 text-center text-white
    text-base leading-none flex flex-col"
    style="background-image: url('img/savings-bg.jpg');">
    You could save a total of
    <span class="text-5xl text-brand-orange-300 font-bold my-3">
      {selectedCurrency.symbol}{formatNumber(data.total.savings)}
    </span>
    Per year with ROCOL
  </div>
{:else}
  <div class="border-l-4 border-brand-orange pl-4">
    <h4 class="text-lg font-bold mb-1">No data to show</h4>
    <p class="leading-tight">
      Please provide details to the left to be able to compare your product
      against a Rocol product.
    </p>
  </div>
{/if}
