<script>
  export let percentage = 0;

  function isPositiveInteger(n) {
    return n >>> 0 === parseFloat(n);
  }
</script>

<div class="flex justify-between items-end w-full h-full">
  <div class="mx-px w-1/2 bg-gray-600 flex-0" style="height: { 100 - percentage }%;" />
  <div
    class="mx-px w-1/2 bg-brand-orange flex-0 flex flex-col h-full items-center
    justify-center text-white font-calibri text-base antialiased leading-tight
    p-4 antialiased font-bold">
    <span>{percentage}%</span>
    <span class="uppercase text-sm">
      {isPositiveInteger(percentage) ? 'more' : 'less'}
    </span>
  </div>
</div>
