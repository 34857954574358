<script>
  import Bar from "../charts/Bar.svelte";
  import Pie from "../charts/Pie.svelte";

  import formatNumber from "../helpers/format-number";

  export let data;
  export let selectedCurrency;
</script>

{#if data && data.fluids}
  <div>
    <div class="border border-gray-200 flex justify-between p-4 mb-3 text-sm">
      <div class="w-1/2 max-w-full">
        <div class="border-b pb-4 mb-4 font-bold">
          Cost per Unit
          <span class="text-brand-orange">({selectedCurrency.symbol} / Unit)</span>
        </div>
        <div>
          <div class="flex flex-col leading-tight mb-4">
            <span class="text-base">Your Product</span>
            <span class="text-2xl">{data.fluids.costPerIbc.you}</span>
          </div>
          <div class="flex flex-col leading-tight">
            <span class="text-base text-brand-orange">ROCOL</span>
            <span class="text-2xl text-brand-orange">
              {data.fluids.costPerIbc.rocol}
            </span>
          </div>
        </div>
      </div>
      <div class="w-1/2 max-w-full pl-8">
        <Pie
          percentage={parseInt((data.fluids.costPerIbc.you - data.fluids.costPerIbc.rocol) / data.fluids.costPerIbc.you * 100)}
          paths={[parseInt(data.fluids.costPerIbc.rocol), parseInt(data.fluids.costPerIbc.you)]} />
      </div>
    </div>
    <div class="border border-gray-200 flex justify-between p-4 mb-3 text-sm">
      <div class="w-1/2 max-w-full">
        <div class="border-b pb-4 mb-4 font-bold">
          Mixed Dilution
          <span class="text-brand-orange">(L / Unit)</span>
        </div>
        <div>
          <div class="flex flex-col leading-tight mb-4">
            <span class="text-base">Your Product</span>
            <span class="text-2xl">{data.fluids.mixedDillution.you}</span>
          </div>
          <div class="flex flex-col leading-tight">
            <span class="text-base text-brand-orange">ROCOL</span>
            <span class="text-2xl text-brand-orange">
              {data.fluids.mixedDillution.rocol}
            </span>
          </div>
        </div>
      </div>
      <div class="w-1/2 max-w-full pl-8">
        <Bar
          percentage={parseInt(((data.fluids.mixedDillution.rocol - data.fluids.mixedDillution.you) / data.fluids.mixedDillution.rocol * 100))} />
      </div>
    </div>
    <div class="border border-gray-200 flex justify-between p-4 mb-3 text-sm">
      <div class="w-1/2 max-w-full">
        <div class="border-b pb-4 mb-4 font-bold">
          Number of Units
          <span class="text-brand-orange">(Units / Year)</span>
        </div>
        <div>
          <div class="flex flex-col leading-tight mb-4">
            <span class="text-base">Your Product</span>
            <span class="text-2xl">{data.fluids.numberOfIbc.you}</span>
          </div>
          <div class="flex flex-col leading-tight">
            <span class="text-base text-brand-orange">ROCOL</span>
            <span class="text-2xl text-brand-orange">
              {data.fluids.numberOfIbc.rocol}
            </span>
          </div>
        </div>
      </div>
      <div class="w-1/2 max-w-full pl-8">
        <Pie
          percentage={parseInt((data.fluids.numberOfIbc.you - data.fluids.numberOfIbc.rocol) / data.fluids.numberOfIbc.you * 100)}
          paths={[parseInt(data.fluids.numberOfIbc.rocol), parseInt(data.fluids.numberOfIbc.you)]} />
      </div>
    </div>
    <div
      class="border border-gray-200 flex justify-between items-center p-4 mb-3
      text-sm">
      <span class="font-bold">
        Dilution Ratio
        <span class="text-brand-orange">(X:1)</span>
      </span>
      <span>{data.fluids.dillutionRatio}:1</span>
    </div>
    <div
      class="border border-gray-200 flex justify-between items-center p-4 mb-3
      text-sm">
      <span class="font-bold">
        Annualised Mixed Emulsion
        <span class="text-brand-orange">(L)</span>
      </span>
      <span>{formatNumber(data.fluids.annualisedMixedEmultion)}</span>
    </div>
    <div
      class="border border-gray-200 flex justify-between items-center p-4 mb-3
      text-sm">
      <span class="font-bold">
        Annualised Cost
        <span class="text-brand-orange">({selectedCurrency.symbol})</span>
      </span>
      <span>{formatNumber(data.fluids.annualisedCost)}</span>
    </div>
    <div class="border-t-4 border-brand-orange">
      <div
        class="border border-gray-200 flex justify-between items-center px-4
        py-8">
        <div class="flex flex-col leading-tight">
          <span class="font-bold">You could save</span>
          <span class="text-gray-600 text-xs">
            <span class="text-brand-orange">*</span>
            Per year with ROCOL
          </span>
        </div>
        <span class="text-5xl font-bold leading-none">
          {selectedCurrency.symbol}{formatNumber(data.fluids.savings)}
          <sup class="text-brand-orange">*</sup>
        </span>
      </div>
    </div>
  </div>
{:else}
  <div class="border-l-4 border-brand-orange pl-4">
    <h4 class="text-lg font-bold mb-1">No data to show</h4>
    <p class="leading-tight">
      Please provide details to the left to be able to compare your product
      against a Rocol product.
    </p>
  </div>
{/if}
