<script>
  import axios from "axios";

  import Header from "./core/Header.svelte";
  import Loader from "./core/Loader.svelte";
  import Login from "./core/Login.svelte";

  import One from "./steps/One.svelte";
  import Two from "./steps/Two.svelte";
  import Three from "./steps/Three.svelte";
  import Four from "./steps/Four.svelte";
  import Five from "./steps/Five.svelte";

  import { step } from "./stores.js";

  const stepComponents = {
    1: One,
    2: Two,
    3: Three,
    4: Four,
    5: Five
  };

  let currentStep;
  let total = Object.keys(stepComponents).length;
  let loggedIn = window.loggedIn || false;

  step.subscribe(v => currentStep = v);
</script>

<main class="bg-gray-100 w-full min-h-screen font-calibri flex flex-col">
  <Header {currentStep} {total} {loggedIn} />
  {#if loggedIn}
  <svelte:component this={stepComponents[currentStep]} />
  {:else}
  <div class="bg-white m-auto my-auto rounded-lg w-2/5">
    <Login />
  </div>
  {/if}
</main>
