<script>
  import Step from "../core/Step.svelte";
  import NextButton from "../core/NextButton.svelte";
  import FloatingInput from "../core/FloatingInput.svelte";
  import {enquiry, step} from "../stores";
  import axios from 'axios';

  export let data = {};
  export let errors = {};
  export let errorMessage = null;

  function sendForm() {
    axios.post('/api/enquires/new', data).then(res => {
      enquiry.set(res.data);
      step.update(n => n + 1)
    })
    .catch(err => {
      errorMessage = err.response.data.message;

      if (err.response.data.errors) {
          errors = err.response.data.errors;
      } else {
        console.log(err);
      }
    });
  }
</script>

<Step>
  <div
    class="flex flex-col-reverse md:flex-row md:justify-between items-start
    md:items-center"
    slot="header">
    <h1 class="text-5xl font-bold leading-none mt-4 md:mt-0">
      New Customer Details
    </h1>
    <NextButton on:click={sendForm}/>
  </div>
  <div class="max-w-2xl mx-auto h-full flex flex-col justify-center py-12 px-8">
    {#if errorMessage != null}
    <p class="error error--static mb-6 text-center">{errorMessage}</p>
    {/if}
    <div class="flex -mx-2 flex-wrap">
      <div class="w-full sm:w-1/2 px-2 max-w-full">
        <FloatingInput label="First Name" name="first_name" bind:value={data.first_name} error={errors.first_name} />
      </div>
      <div class="w-full sm:w-1/2 px-2 max-w-full">
        <FloatingInput label="Last Name" name="last_name" bind:value={data.last_name} error={errors.last_name} />
      </div>
      <div class="w-full sm:w-1/2 px-2 max-w-full">
        <FloatingInput label="Company" name="company" bind:value={data.company} error={errors.company} />
      </div>
      <div class="w-full sm:w-1/2 px-2 max-w-full">
        <FloatingInput label="Position" name="position" bind:value={data.position} error={errors.position} />
      </div>
      <div class="w-full sm:w-1/2 px-2 max-w-full">
        <FloatingInput label="Email Address" type="email" name="email" bind:value={data.email} error={errors.email} />
      </div>
      <div class="w-full sm:w-1/2 px-2 max-w-full">
        <FloatingInput label="Phone Number" type="tel" name="phone" bind:value={data.phone} error={errors.phone} />
      </div>
      <div class="w-full px-2">
        <div class="border border-gray-300">
          <label class="flex items-center border-t-4 border-brand-orange p-6">
            <input
              type="checkbox"
              class="form-checkbox h-6 w-6 text-brand-orange"
              name="newsletter_opt_in"
              value="1"
              bind:checked={data.newsletter_opt_in}
            />
            <div class="ml-4 flex flex-col">
              <span class="text-xl leading-none">Opt in for newsletters</span>
              <span class="text-sm">
                By subsciribing to our newsletter, you agree to receive our
                emails
              </span>
            </div>
          </label>
        </div>
        {#if errors.newsletter_opt_in}
        <p class="error error--static">{errors.newsletter_opt_in}</p>
        {/if}
      </div>
    </div>
  </div>
</Step>
