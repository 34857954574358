<script>
  export let current = false;
</script>

<div class={`border-r border-gray-200 bg-white ${current ? 'z-10' : ''}`}>
  <button
    on:click
    class={`bg-white py-4 px-8 border-t-4 uppercase tracking-widest font-bold text-sm ${current ? 'border-brand-orange text-brand-orange' : 'border-white text-black'}`}>
    <slot />
  </button>
</div>
