<script>
  import NextButton from "./NextButton.svelte";
</script>

<section class="max-w-5xl mx-auto my-16 px-8 w-full flex flex-col">
  <header class="mb-4 w-full">
    <slot name="header">
      <div class="flex justify-between items-center">
        <h1 class="text-5xl font-bold leading-none mt-4 md:mt-0">Heading</h1>
        <NextButton disabled={true} />
      </div>
    </slot>
  </header>
  <main
    class="bg-white border border-gray-200 w-full flex-grow"
    style="transition: height .2s ease-in-out;">
    <slot />
  </main>
</section>
