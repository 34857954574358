import axios from 'axios';
import App from './App.svelte'
import './css/main.css'
import './css/print.css'

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

const app = new App({
  target: document.body
});

export default app
