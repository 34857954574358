<script>
  export let type = "password";
  export let id = `${type}-input-${Math.random()
    .toString(36)
    .substring(7)}`;
  export let label;
  export let name;
  export let required = false;
  export let className = "";
  export let error = null;
  export let value = '';

  let v;

  function hasValue() {
    v = this.value ? true : false;
  }
</script>

<div class="relative mb-4 border border-gray-300">
  <input
    bind:value={value}
    on:change={hasValue}
    on:keyup={hasValue}
    {id}
    class={`floating-input px-6 pt-8 pb-4 w-full text-lg border-l-4 border-white focus:border-brand-orange ${className}`}
    type="password"
    placeholder=""
    name={name || ''}
    {required}/>
  <label for={id} class={`p-6 floating-label text-lg ${v ? '-float' : ''}`}>
    {label}
  </label>

  {#if error}
  <span class="error block">{error}</span>
  {/if}
</div>

