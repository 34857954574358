<script>
  import { onMount } from "svelte";

  export let paths;
  export let percentage;

  function getCoordinatesForPercent(percent) {
    const x = Math.cos(2 * Math.PI * percent);
    const y = Math.sin(2 * Math.PI * percent);
    return [x, y];
  }

  function calculateTotal() {
    return paths.reduce((acc, path) => {
      return acc + path;
    }, 0);
  }

  $: diameters = () => {
    let cumulativePercent = 0;
    let total = calculateTotal();

    return paths.map(path => {
      // destructuring assignment sets the two variables at once
      const [startX, startY] = getCoordinatesForPercent(cumulativePercent);

      // each slice starts where the last slice ended, so keep a cumulative percent
      cumulativePercent += path / total;

      const [endX, endY] = getCoordinatesForPercent(cumulativePercent);

      // if the slice is more than 50%, take the large arc (the long way around)
      const largeArcFlag = path / total > 0.5 ? 1 : 0;

      // create an array and join it just for code readability
      const pathData = [
        `M ${startX} ${startY}`, // Move
        `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
        `L 0 0` // Line
      ].join(" ");

      console.log(pathData);

      return pathData;
    });
  };
</script>

<div class="w-full">
  <div class="relative" style="padding-top: 100%;">
    <svg
      viewBox="-1 -1 2 2"
      class="absolute top-0 left-0 w-full h-full"
      style="transform: rotate(-90deg)">
      <defs>
        <mask id="mask">
          <path
            d="M-0.816964286,0a0.816964286,0.816964286 0 1,0
            1.633928572,0a0.816964286,0.816964286 0 1,0 -1.633928572,0"
            fill="none"
            stroke="white"
            stroke-width=".2"
            transform="scale(-1, 1)" />
        </mask>
      </defs>
      {#each diameters() as d, i}
        <path
          {d}
          fill={i % 2 === 0 ? '#FE5700' : '#808080'}
          mask="url(#mask)" />
      {/each}
    </svg>
    {#if percentage}
      <div
        class="absolute w-full h-full inset-0 flex flex-col justify-center
        items-center leading-none">
        <span class="text-2xl mb-1">{percentage}%</span>
        <span class="text-sm text-brand-orange uppercase tracking-widest">
          Savings
        </span>
      </div>
    {/if}
  </div>
</div>
