<script>
  import axios from 'axios';

  import { step } from "../stores.js";
  import Logo from "../icons/Logo.svelte";
  import Restart from "../icons/Restart.svelte";

  export let loggedIn = false;
  export let currentStep = 1;
  export let total = 5;

  function logout() {
    axios.post('/logout').then(() => window.location.reload());
  }
</script>

<header class="bg-white sm:h-header">
  <div
    class="max-w-5xl mx-auto flex flex-col-reverse sm:flex-row justify-between
    items-center px-8">
    <div class="sm:w-1/3 h-header overflow-visible">
      <Logo />
    </div>
    <div class="sm:w-1/3 text-center text-brand-orange">
      Step {currentStep} of {total}
    </div>
    <div class="sm:w-1/3 flex justify-end p-4 sm:p-0">
      {#if loggedIn}
      <button
        class="font-bold text-brand-orange uppercase tracking-wider antialiased
        inline-flex leading-none items-center px-4 py-3 border-brand-orange
        rounded-lg border text-xs"
        on:click={() => step.update(() => 1)}>
        <Restart />
        <span class="pl-2">Restart</span>
      </button>
      <button
        on:click={logout}
        class="font-bold text-white bg-gray-600 uppercase tracking-wider
        antialiased inline-flex leading-none items-center px-4 py-3 rounded-lg
        text-xs ml-4 whitespace-no-wrap">
        Log out
      </button>
      {:else}
      <small>Please login.</small>
      {/if}
    </div>
  </div>
</header>
