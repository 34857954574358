<script>
  import { fade } from "svelte/transition";
  import Step from "../core/Step.svelte";
  import BackButton from "../core/BackButton.svelte";
  import NextButton from "../core/NextButton.svelte";
  import { onMount } from 'svelte'
  import Loader from "../core/Loader.svelte";
  import {enquiry, step} from "../stores";
  import axios from 'axios';

  let option;

  const getVariants = axios.get(`/api/variants?filter[volume.id]=${$enquiry.volume.id}`)

  onMount(() => {
      if ($enquiry.variant && $enquiry.variant.id) {
        option = $enquiry.variant.id;
      }
  });

  function selectProduct() {
    axios.post('/api/enquires/select/variant', {
      enquiry_id: $enquiry.enquiry_id,
      variant_id: option
    }).then(res => {
      enquiry.set(res.data.data);
      step.update(n => n + 1)
    }).catch(err => {
      console.log(err);
    })
  }
</script>

<Step>
  <div
    class="flex flex-col-reverse md:flex-row md:justify-between items-start
    md:items-center"
    slot="header">
    <h1 class="text-5xl font-bold leading-none mt-4 md:mt-0">
      Select a ROCOL variant
    </h1>

    <span>
      <BackButton text="Back" on:click={() => step.update(() => 2)} />
      <NextButton on:click={selectProduct} disabled={!option} />
    </span>
  </div>
  <div class="grid p-4 grid-gap-4 grid-columns-12">
    {#await getVariants}
      <div class="col-span-12 py-8">
        <Loader />
      </div>
    {:then variants}
      {#each variants.data.data as variant, i}
        <label
          class={`flex flex-col justify-between border col-span-12 sm:col-span-6 ${variant.featured ? 'md:col-span-4' : 'md:col-span-3'} ${option === variant.id ? 'bg-gray-100' : 'bg-white'}`}
          in:fade={{ duration: 250, delay: 150 + 75 * i }}>
          <div
            class={`p-4 pb-6 text-xl text-center font-bold leading-none border-t-4 ${option === variant.id ? 'border-brand-orange' : 'border-white'}`}>
            <div
              style="width: 122px; height: 82px; background-image: url('{variant.product.images[0]}');"
              class="bg-contain bg-no-repeat bg-center mx-auto mb-6" />
            {variant.product.title}
          </div>
          <div class="px-4">
            <div
              class="border-t py-4 flex justify-between items-center
              leading-none">
              <span class="text-sm font-bold">Select variant</span>
              <input
                type="radio"
                name="variant"
                value={variant.id}
                bind:group={option}
                class={`form-radio text-brand-orange border-brand-orange block ml-auto w-5 h-5 ${option === variant.id ? 'focus:border-transparent focus:shadow-none' : ''}`} />
            </div>
          </div>
        </label>
      {/each}
    {:catch error}
      <p>Something went wrong: {error.message}</p>
    {/await}
  </div>
</Step>
