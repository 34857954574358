<script>
  import axios from "axios";
  import FloatingInput from "./FloatingInput.svelte";
  import PasswordInput from "./PasswordInput.svelte";

  let data = {};
  let errors = {};
  let errorMessage = null;

  function login(e) {
    e.preventDefault();

    axios.post('/login', data)
      .then(() => {
          window.location.reload();
      })
      .catch(err => {
        errorMessage = err.response.data.message;
        errors = err.response.data.errors
      });
  }

  function passwordReset() {
    const link = document.createElement('a');
    link.setAttribute('href', '/password/reset');
    document.body.appendChild(link);
    link.click();
  }
</script>

<form class="p-20" on:submit={login}>
  <div class="mb-10">
    <FloatingInput label="Email" name="email" bind:value={data.email} error={errors.email} />
    <PasswordInput label="Password" type="password" name="password" bind:value={data.password} error={errors.password} />
    <div class="flex justify-between">
      <button
        type="button"
        on:click={passwordReset}
        class="h-auto text-brand-orange text-s">
        Reset Password
      </button>
      <button
        type="submit"
        class="bg-orange rounded text-white bg-brand-orange py-4 px-6">
        Login
      </button>
    </div>
  </div>

  <div class="text-center">

  </div>
</form>
